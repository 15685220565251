.App {
  text-align: center;
  overflow-x: hidden;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}
.modal-header .btn-close{
  color: #fff;
  background: #fff;
}
.modal-header .btn-close:after{
  content: "X";
  top: 25px;
  right: 20px;
  position: absolute;
  margin-top: -10px;
  font-size: 20px;
  background: #dd0d30;
  color: #fff;
  border-radius: 4px;
  padding: 2px 10px;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.offcanvas-start{
  width: 250px !important;
}

.sidebar{
  width: 340px !important;
}

.link-div{
  display: flex;
  color: rgb(221, 221, 221);
  text-decoration: none;
  padding: 14px 20px;
}

.nav-link.nav-group-toggle{
  padding-left: 30px !important;
}

.link-div:hover{
  color: #fff;
}

.header-row{
  display: flex;
}

.container-fluid.routing-container{
  padding-left: 0;
  padding-right: 0;
}

.col-9.content-column{
  padding-left: 0;
  padding-right: 0;
  right: 0;
}

.col-3.sidebar-column{
  padding-right: 0;
}

.navbar{
  height: 64px;
}

.centerColumn{
  display: flex;
  justify-content: center;
}

.add-data-form .col-md-12,
.add-data-form .col-md-6,
.add-data-form .col-md-4 {
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: flex-start;
}

.pointer-cursor{
  cursor: pointer;
}